<template>
	<div>
		<div v-if="app.isMobile">
			<main-m-header></main-m-header>
			<router-view></router-view>
			<main-m-footer></main-m-footer>
		</div>
		<div v-else>
			<div class="overlay_fullpage" style="display: none;"></div>
			<img :src="img_src" hidden="" alt="" />
			<main-header></main-header>
			<router-view></router-view>
		</div>
	</div>
</template>

<script>
	import Components from '@/components/layouts'
	export default {
		inject: ['app'],
		components: {
			...Components
		},
		data() {
			return {
				img_src: '/images/pc/logo_icon.png'
			}
		}
	}
</script>

<style>
	
</style>